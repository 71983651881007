import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Alert, IconButton, InputAdornment, Link, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link as RouterLink } from 'react-router-dom';
import * as Yup from 'yup';
import { useAuthContext } from '../../auth/useAuthContext';
import FormProvider, { RHFTextField } from '../../components/hook-form';
import Iconify from '../../components/iconify';
import { useLocales } from '../../locales';
import { PATH_AUTH } from '../../routes/paths';
export default function AuthLoginForm() {
    const { login, error } = useAuthContext();
    const { translate } = useLocales();
    const [showPassword, setShowPassword] = useState(false);
    const LoginSchema = Yup.object().shape({
        email: Yup.string()
            .required(`${translate('Email is required')}`)
            .email(`${translate('Email must be a valid email address')}`),
        password: Yup.string().required(`${translate('Password is required')}`),
    });
    const defaultValues = {
        email: '',
        password: '',
    };
    const methods = useForm({
        resolver: yupResolver(LoginSchema),
        defaultValues,
    });
    const { reset, setError, handleSubmit, formState: { isSubmitting }, } = methods;
    useEffect(() => {
        if (error?.message?.includes('email')) {
            setError('email', { message: error?.message });
        }
        if (error?.message?.includes('password')) {
            setError('password', {
                message: error?.message,
            });
        }
    }, [error, setError]);
    const onSubmit = async (data, e) => {
        e?.preventDefault();
        try {
            await login(data.email, data.password);
        }
        catch (err) {
            reset();
            console.error(`${translate(err)}`);
            setError('afterSubmit', {
                ...err,
                message: err.message || err || error?.message,
            });
        }
    };
    return (_jsxs(FormProvider, { methods: methods, onSubmit: handleSubmit(onSubmit), children: [_jsxs(Stack, { spacing: 3, children: [!!error && _jsx(Alert, { severity: "error", children: `${translate(error?.message)}` }), _jsx(RHFTextField, { name: "email", label: `${translate('Email address')}`, InputLabelProps: { shrink: true }, fullWidth: true, margin: "dense" }), _jsx(RHFTextField, { name: "password", label: `${translate('Password')}`, type: showPassword ? 'text' : 'password', InputLabelProps: { shrink: true }, fullWidth: true, margin: "dense", InputProps: {
                            endAdornment: (_jsx(InputAdornment, { position: "end", children: _jsx(IconButton, { onClick: () => setShowPassword(!showPassword), edge: "end", children: _jsx(Iconify, { icon: showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill' }) }) })),
                        } })] }), _jsx(Stack, { alignItems: "flex-end", sx: { my: 2 }, children: _jsx(Link, { component: RouterLink, to: PATH_AUTH.resetPassword, variant: "body2", color: "inherit", underline: "always", children: `${translate('Forgot password?')}` }) }), _jsx(LoadingButton, { fullWidth: true, color: "inherit", size: "large", type: "submit", variant: "contained", loading: isSubmitting, sx: {
                    bgcolor: 'text.primary',
                    mb: '2rem',
                    color: (theme) => (theme.palette.mode === 'light' ? 'common.white' : 'grey.800'),
                    '&:hover': {
                        bgcolor: 'text.primary',
                        color: (theme) => (theme.palette.mode === 'light' ? 'common.white' : 'grey.800'),
                    },
                }, children: `${translate('Login')}` })] }));
}
